import _defineProperty from "@babel/runtime/helpers/defineProperty";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { getLang, getClang } from '@client/common/utils/fn';
import { UPDATE_COMMON_LANGPKG, UPDATE_LANG, GET_CLANG_LIST, UPDATE_CLANG, UPDATE_USER_INFO, GET_DECORATION_INFO_LIST } from './actions';
var merge = require('deepmerge');
var initialState = {
  langPkg: {},
  lang: parseInt(getLang(), 10),
  clangList: [],
  clang: parseInt(getClang() || '1000', 10),
  userInfo: {},
  decorations: [] // 徽章列表
};

function commonReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var type = action.type,
    payload = action.payload;
  switch (type) {
    case UPDATE_COMMON_LANGPKG:
      return _objectSpread(_objectSpread({}, state), {}, {
        langPkg: merge(state.langPkg, payload)
      });
    case UPDATE_LANG:
      return _objectSpread(_objectSpread({}, state), {}, {
        lang: payload
      });
    case GET_CLANG_LIST:
      return _objectSpread(_objectSpread({}, state), {}, {
        clangList: payload
      });
    case UPDATE_CLANG:
      return _objectSpread(_objectSpread({}, state), {}, {
        clang: payload,
        userInfo: _objectSpread(_objectSpread({}, state.userInfo), {}, {
          clang: payload
        })
      });
    case UPDATE_USER_INFO:
      return _objectSpread(_objectSpread({}, state), {}, {
        userInfo: payload,
        lang: payload.lang ? parseInt(payload.lang, 10) : state.lang,
        clang: payload.clang ? parseInt(payload.clang, 10) : state.clang
      });
    case GET_DECORATION_INFO_LIST:
      return _objectSpread(_objectSpread({}, state), {}, {
        decorations: payload
      });
    default:
      return state;
  }
}
export default commonReducer;