import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["type"];
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { INIT_ROOM_INFO, commonMergeTypes } from './actions';
var initialState = {
  isOnline: false,
  // 是否在播
  roomSort: 0,
  // 直播间类型
  giftList: [],
  // 直播间礼物列表
  giftListLoaded: false,
  // 直播间礼物列表是否已加载成功
  accountBalance: {
    // 用户账户余额
    coin: {},
    // 金币
    gem: {},
    // 宝石
    diamond: {} // 钻石
  },

  // 流数据，单直播间只有1个。但存在多个播放器的情况则会有多个流，要通过直播间id区分
  // 每个流数据有roomLine和playbackVideoData两个字段，分别表示直播流地址和回放地址
  stream: {},
  roomInfo: {},
  userInfo: {},
  // 当前用户信息
  bsLotteryOn: false,
  // 是否正在进行弹幕分享抽奖
  multiNodeLotteryOn: false,
  // 是否正在进行多节点抽奖
  anchorPKOn: false,
  // 是否正在进行主播 pk,
  anchorPKOnFetched: false,
  // 是否已经得到主播 pk状态
  mcTogetherOn: false,
  // 是否正在进行连麦
  msgWhiteList: [],
  // 重复聊天消息白名单，存放弹幕抽奖、弹幕投票内容等
  currFansBarrage: {
    barrageType: 0,
    fanLevel: 0,
    color: '#fff',
    open: true
  },
  // 粉丝弹幕-最后一次使用的粉丝
  wearBadgeInfo: null,
  // 粉丝团-当前佩戴的徽章
  giftPackNew: false,
  // 是否有新得到的背包礼物
  normalActivityList: null,
  // 直播间普通展示位
  specialActivity: null,
  // 直播间S展示位
  viewCount: null,
  // 直播间人气
  followCount: null,
  // 主播粉丝数量
  facialsList: [] // 直播间表情列表
};

function commonReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var type = action.type,
    payload = _objectWithoutProperties(action, _excluded);
  if (commonMergeTypes.indexOf(type) > -1) {
    return _objectSpread(_objectSpread({}, state), payload);
  }
  switch (type) {
    case INIT_ROOM_INFO:
      return _objectSpread(_objectSpread({}, state), {}, {
        roomInfo: _objectSpread({}, payload)
      });
    default:
      return state;
  }
}
export default commonReducer;